import * as React from "react";
import FocusedLayout from "../components/common/FocusedLayout";

const NotFoundPage = () => (
  <FocusedLayout>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </FocusedLayout>
);

export default NotFoundPage;
