import React from "react";
import { Theme, WithStyles, withStyles } from "@material-ui/core";

const styles = (theme: Theme) => ({
  focusedContent: {
    padding: theme.spacing(4),
    maxWidth: 800,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "64px",
    minHeight: "calc(100vh - 64px)",
    display: "flex",
    flexDirection: "column" as "column"
  }
});

const FocusedLayout: React.SFC<WithStyles<typeof styles>> = ({
  children,
  classes
}) => {
  return <div className={classes.focusedContent}>{children}</div>;
};

export default withStyles(styles)(FocusedLayout);
